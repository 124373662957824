import { ThemeProvider } from "@mui/material/styles"
import React, { useContext } from "react"
import useLocalStorage from "../hooks/UseLocalStorage"
import { Color } from "../models/ServerModels"
import { themeMap } from "../themes/theme"

interface ISettingsContext {
    name: string
    setName: (name: string) => void
    color: Color
    setColor: (color: Color) => void
    autoUseName: boolean
    setAutoUseName: (autoUseName: boolean) => void
    previousRoomId: string
    setPreviousRoomId: (id: string) => void
    shouldSavePreviousRoomId: boolean
    setShouldSavePreviousRoomId: (id: boolean) => void
    theme: string
    setTheme: (theme: string) => void
}

const SettingsContext = React.createContext({} as ISettingsContext)

interface ISettingsProviderProps {
    children: JSX.Element | JSX.Element[]
}

export function useSettings() {
    return useContext(SettingsContext)
}

export default function SettingsProvider({ children }: ISettingsProviderProps) {
    const [name, setName] = useLocalStorage('name', '')
    const [color, setColor] = useLocalStorage('color', Color.Red)
    const [autoUseName, setAutoUseName] = useLocalStorage('autoUseName', false)
    const [previousRoomId, setPreviousRoomId] = useLocalStorage('previousRoomId', '')
    const [shouldSavePreviousRoomId, setShouldSavePreviousRoomId] = useLocalStorage('savePreviousRoomId', true)
    const [theme, setTheme] = useLocalStorage('theme', 'dark')
    const useTheme = getThemeFromKey(theme)

    function getThemeFromKey(key: string) {
        var useKey = key
        if (!themeMap.has(key)) {
            useKey = 'dark'
        }
        const newTheme = themeMap.get(key)!.theme
        return newTheme
    }

    const value: ISettingsContext = {
        name: name,
        setName: setName,
        color: color,
        setColor: setColor,
        autoUseName: autoUseName,
        setAutoUseName: setAutoUseName,
        previousRoomId: previousRoomId,
        setPreviousRoomId: setPreviousRoomId,
        shouldSavePreviousRoomId: shouldSavePreviousRoomId,
        setShouldSavePreviousRoomId: setShouldSavePreviousRoomId,
        theme: theme,
        setTheme: setTheme
    }

    return (
        <SettingsContext.Provider value={value}>
            <ThemeProvider theme={useTheme}>
                {children}
            </ThemeProvider>
        </SettingsContext.Provider>
    )
}

interface ICustomThemeProviderProps {
    children: JSX.Element | JSX.Element[]
}