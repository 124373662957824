import { Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"
import { Chart } from "chart.js"
import { Bar } from "react-chartjs-2"
import { RoomProperties, RoomState, RoomStatus } from "../../../models/ServerModels"

interface ScoreDisplayProps {
    state: RoomState,
    properties: RoomProperties,
    isGameFinished: boolean
}

export default function ScoreDisplay({ state, properties, isGameFinished }: ScoreDisplayProps) {
    const theme = useTheme()

    Chart.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
      );

    function getBarData() {
        let wordsPlayed = properties.numberOfWords - state.remainingWords.length - (state.status === RoomStatus.GuessResult || state.status === RoomStatus.Results ? 0 : 1)
        let data = [state.score, wordsPlayed - state.score]
        let labels = ["Correct", "Incorrect"]
        let bgColors = [theme.palette.success.main, theme.palette.error.main]
        if (!isGameFinished) {
            data.push(state.remainingWords.length + (state.status === RoomStatus.GuessResult ? 0 : 1))
            labels.push("Remaining")
            bgColors.push(theme.palette.text.primary)
        }
        return {
            labels: labels,
            datasets: [{
                data: data,
                borderWidth: 0,
                backgroundColor: bgColors,
            }]        
        }
    }

    return <div style={{ width: "250px", height: "180px"}}>
        <Typography variant="h6">Score: {state.score}</Typography>

        <Bar
            data={getBarData()}
            options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    y: {
                        max: properties.numberOfWords,
                        beginAtZero: true,
                        border: {
                            display: false
                        },
                        grid: {
                            display: false,
                        },
                        ticks: {
                            color: theme.palette.text.primary,
                            stepSize: 1
                        }
                    },
                    x: {
                        grid: {
                            display: false
                        },
                        ticks: {
                            color: theme.palette.text.primary
                        }
                    }
                },
            }}>

        </Bar>
    </div>
}