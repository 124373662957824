import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Switch, TextField } from "@mui/material"
import { useState } from "react"
import { isBlank } from "../../../util/util"
import { defaultNumberOfWords, RoomProperties } from "../../../models/ServerModels"

interface ICreateDialogContentProps {
    onCancel: () => void
    onCreate: (properties: RoomProperties) => void
    existingProperties: RoomProperties | null
}

export default function CreateDialogContent({ onCancel, onCreate, existingProperties }: ICreateDialogContentProps) {

    const [nameText, setNameText] = useState(existingProperties?.name ?? "")
    const [numberOfWords, setNumberOfWords] = useState(existingProperties?.numberOfWords ?? defaultNumberOfWords)
    const [useCustomWords, setUseCustomWords] = useState(false)
    const [customWords, setCustomWords] = useState("")

    function handleCreateClicked() {
        let properties = new RoomProperties(nameText, numberOfWords, useCustomWords ? customWords.replace(/ /g,'').split("\n") : [])
        onCreate(properties)
    }

    return (
        <>
            <DialogTitle id="form-dialog-title">
                {existingProperties == null ? "Create Room" : "Edit Room"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {existingProperties == null ? "Create a new room. Anyone that uses the room's url will be able to join." : ""}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Room Name"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                    defaultValue={nameText}
                    style={{ marginTop: "24px" }}
                    onChange={(e) => setNameText(e.currentTarget.value)} />
                <TextField
                    id="number-of-words"
                    label="Number of words"
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    defaultValue={numberOfWords}
                    onChange={e => {
                        setNumberOfWords(parseInt(e.currentTarget.value))
                    }}
                    style={{ marginTop: "24px" }}
                />

                <FormControl style={{ width: '100%', marginTop: '16px' }}>
                    <FormControlLabel
                        id="custom-words-check"
                        control={
                            <Switch
                                checked={useCustomWords} onChange={(e) => setUseCustomWords(e.target.checked)} name="use-custom-words" />}
                        label="Use custom word list"
                    />
                </FormControl>

                {
                    useCustomWords ? <div>
                        <DialogContentText style={{ marginTop: "24px" }}>
                            A list of words to use as the word bank. Put each word on a separate line.
                        </DialogContentText>
                        <TextField
                            id="custom-words"
                            label="Words"
                            multiline
                            fullWidth
                            defaultValue={customWords}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={e => {
                                setCustomWords(e.currentTarget.value)
                            }}
                            style={{ marginTop: "24px" }}
                        />
                    </div>
                        : <></>
                }



            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={handleCreateClicked}
                    disabled={isBlank(nameText)}
                    color="primary">
                    Submit
                </Button>
            </DialogActions>
        </>
    )
}
