import { Container, Typography, IconButton, Grid, ListItem, Box, Paper, Tooltip, Stack, List, ListItemText, ListSubheader } from "@mui/material";
import { useTheme } from "@mui/material/styles"
import Button from "@mui/material/Button";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useEffect, useState } from "react";
import { useRoom } from "../../contexts/RoomProvider";
import { ReviewHint, RoomState, RoomStatus } from "../../models/ServerModels";
import HintCard from "./cards/HintCard";
import HintTextInput from "./controls/HintTextInput";
import Results from "./Results";
import PlayerList from "./status/PlayerList";
import WaitingToStart from "./WaitingToStart";
import anime from "animejs";
import ScoreDisplay from "./status/ScoreDisplay";

export default function RoomPlaying() {
    const { roomState, roomProperties, player, isCurrentPlayer, isModerator, giveHint, moderatorApprove, giveGuess, reviewGuess } = useRoom()
    const [text, setText] = useState("")
    const [submittedText, setSubmittedText] = useState("")
    const [flippedCards, setFlippedCards] = useState(false)
    const theme = useTheme()

    useEffect(() => {
        if (roomState.status !== RoomStatus.GivingClues && roomState.status !== RoomStatus.Guessing) {
            setText("")
            setSubmittedText("")
        }
        let shouldFlipCards = getFlipCards(roomState)
        if (flippedCards !== shouldFlipCards) {
            setFlippedCards(shouldFlipCards)
            anime({
                targets: ".hint-card-animate", // Only animate actual game cards
                scale: [{ value: 1 }, { value: 1.05 }, { value: 1, delay: 150 }],
                rotateY: { value: shouldFlipCards ? 180 : 0 },
                easing: 'easeInOutSine',
                delay: anime.stagger(100, { from: 'center' }),
                duration: 400
            })
        }
    }, [roomState])

    function getFlipCards(state: RoomState): boolean {
        let isStateCurrentPlayer = state.players[state.currentPlayerIndex].id == player?.id
        return state.status !== RoomStatus.GivingClues && (!isStateCurrentPlayer || state.status !== RoomStatus.ReviewClues)
    }

    function renderBoard() {
        return <>
            <Grid container spacing={2}>
                <Grid item={true} xs={3}>
                    <ScoreDisplay state={roomState} properties={roomProperties} isGameFinished={false} />
                </Grid>
                <Grid item={true} xs={6}>
                    <Typography variant="h2" style={{ marginTop: "16px" }}>{roomState.currentWord}</Typography>
                </Grid>
                <Grid item={true} xs={3}>
                    <PlayerList players={roomState.players} currentPlayerIndex={roomState.currentPlayerIndex} />
                </Grid>
            </Grid>

            {
                roomState.status === RoomStatus.ReviewGuess || roomState.status === RoomStatus.GuessResult ?
                    <div>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
                            <Typography variant="h4" color={roomState.guessApproved ? theme.palette.success.main : theme.palette.error.main}>{`Guess: ${roomState.guess}`}</Typography>
                            <div style={{ alignItems: "center", display: "flex", marginLeft: "16px" }}>
                                {roomState.guessApproved ? <CheckIcon color="success" /> : <ClearIcon color="error" />}
                            </div>
                        </div>
                        {
                            isModerator && roomState.status === RoomStatus.ReviewGuess ?
                                <div>
                                    <Tooltip title="Approve">
                                        <IconButton
                                            color="success"
                                            aria-label="approve"
                                            component="label"
                                            onClick={() => reviewGuess(true)} >
                                            <CheckIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Deny">
                                        <IconButton
                                            color="error"
                                            aria-label="deny"
                                            component="label"
                                            onClick={() => reviewGuess(false)} >
                                            <ClearIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div> : <></>
                        }
                    </div>
                    : <></>
            }

            <Grid2 container alignItems="center" justifyContent="center" style={{ minHeight: '40vh', marginBottom: "16px" }}>
                {roomState.players.filter((_, index) => index !== roomState.currentPlayerIndex).map(player => (
                    <React.Fragment key={`hint-card-div-${player.id}`} >
                        <HintCard player={player} invertFlip={false}
                            showApproval={roomState.status === RoomStatus.ReviewClues && !isCurrentPlayer} canModerate={isModerator} approved={false} />
                    </React.Fragment>
                ))}
            </Grid2>

            <Grid container spacing={2}>
                <Grid item={true} xs={3}>
                </Grid>
                <Grid item={true} xs={6}>
                    {
                        (roomState.status === RoomStatus.GivingClues && !isCurrentPlayer) || (roomState.status === RoomStatus.Guessing && isCurrentPlayer) ?
                            <HintTextInput text={text} setText={setText} submittedText={submittedText} roomStatus={roomState.status} handleReadyClicked={function (): void {
                                let useText = player?.isReady === true ? "" : text
                                setSubmittedText(useText)
                                if (roomState.status === RoomStatus.GivingClues) {
                                    giveHint(useText)
                                } else if (roomState.status === RoomStatus.Guessing) {
                                    giveGuess(useText)
                                }
                            }} />
                            : <></>
                    }
                    {
                        (roomState.status === RoomStatus.ReviewClues || roomState.status === RoomStatus.ReviewGuess) ?
                            <>
                                {
                                    isModerator ? <Button
                                        id="review-continue-button"
                                        className="actionButton"
                                        variant="contained"
                                        color="primary"
                                        onClick={moderatorApprove}
                                        style={{ margin: "12px" }}>
                                        Continue
                                    </Button> : <></>
                                }
                                <Typography>{isModerator ? "Review and hit Continue" : `Waiting for review (${roomState.players[roomState.moderatorPlayerIndex].name})`}</Typography>
                            </>
                            : <></>
                    }

                    {
                        roomState.status === RoomStatus.Guessing && !isCurrentPlayer ?
                            <Typography> Waiting for guess </Typography>
                            : <></>

                    }
                </Grid>
                <Grid item={true} xs={3}>
                    <List
                        sx={{
                            width: '100%',
                            maxWidth: 360,
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: 250,
                            '& ul': { padding: 0 },
                        }}
                        subheader={<li />}
                    >
                            {roomState.historyLog.slice(0).reverse().map((logItem, index) => (
                                <ListItem key={`history-item-${index}`}>
                                    <ListItemText primary={logItem} />
                                </ListItem>

                            ))}
                    </List>
                </Grid>
            </Grid>

        </>
    }

    function renderState() {
        switch (roomState.status) {
            case RoomStatus.WaitingToStart: return <WaitingToStart />
            case RoomStatus.GivingClues:
            case RoomStatus.ReviewClues:
            case RoomStatus.Guessing:
            case RoomStatus.ReviewGuess: 
            case RoomStatus.GuessResult: return renderBoard()
            case RoomStatus.Results: return <Results />
        }
    }

    return (
        <Container style={{ height: "80vh", textAlign: "center", marginTop: "24px" }}>
            {renderState()}
        </Container>
    )
}