import { createTheme } from "@mui/material/styles"
import { red, green, blue, purple, pink, orange, yellow, cyan, lime, deepPurple, deepOrange } from "@mui/material/colors"

export function buildTheme(isDark: boolean, primary: string, secondary: string, background: string, paper: string) {
  return (createTheme as any)({
    palette: {
      mode: isDark ? 'dark' : 'light',
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
      background: {
        default: background
      }
    },
    overrides: {
      MuiCssBaseline: {
        bgColor: background,
        '@global': {
          body: {
            backgroundColor: background,
          }
        }
      },
      MuiDialog: {
        paper: {
          backgroundColor: paper
        }
      }
    }
  })
}

export class CustomThemeParams {
  constructor(public isDark: boolean, public primary: string, public secondary: string, public background: string, public paper: string) { }
}

export const darkTheme = buildTheme(true, blue[500], red[500], "#222", "#222")
export const lightTheme = buildTheme(false, blue[600], red[800], '#FFF', '#FFF')
export const notTooBrightTheme = buildTheme(false, blue[600], red[800], '#CFCFCF', '#E6E6E6')
export const mirageTheme = buildTheme(true, purple[500], pink[500], '#25274d', '#25274d')
export const leafTheme = buildTheme(true, '#74D334', '#398A3A', '#21351C', '#21351C')
export const autumnTheme = buildTheme(true, '#E09119', '#E2B147', '#cf8517', '#A13219')
export const oceanTheme = buildTheme(true, '#22B8CF', blue[500], '#0B7285', '#0B7285')
export const linenTheme = buildTheme(false, orange[400], orange[500], '#e7e3d4', '#e7e3d4')
export const blueTheme = buildTheme(true, blue[500], blue[300], '#2f4454', '#2f4454')
export const pitchBlackTheme = buildTheme(true, '#919090', '#ECF4F4', 'black', 'black')
export const slateTheme = buildTheme(true, '#88ABB8', '#687387', '#606060', '#606060')
export const cottonCandyTheme = buildTheme(false, '#D91AD9', '#7B1B7E', '#F4D2F2', '#F4D2F2')

export const chartColors = [
  blue[500], red[500], green[500], yellow[500], purple[500], orange[500], cyan[500], pink[500], lime[500], deepPurple[500], deepOrange[500]
]
export const themeMap = new Map([
  ['dark', { theme: darkTheme, name: 'Dark' }],
  ['light', { theme: lightTheme, name: 'Light' }],
  ['notTooBright', { theme: notTooBrightTheme, name: 'Not Too Bright' }],
  ['mirage', { theme: mirageTheme, name: 'Mirage' }],
  ['leaf', { theme: leafTheme, name: 'Leaf' }],
  ['autumn', { theme: autumnTheme, name: 'Autumn' }],
  ['ocean', { theme: oceanTheme, name: 'Ocean' }],
  ['linen', { theme: linenTheme, name: 'Linen' }],
  ['blue', { theme: blueTheme, name: 'Blue' }],
  ['pitchBlack', { theme: pitchBlackTheme, name: 'Pitch Black' }],
  ['slate', { theme: slateTheme, name: 'Slate' }],
  ['cottonCandy', { theme: cottonCandyTheme, name: 'Cotton Candy' }]
])