import { Button, Typography } from "@mui/material";
import { useRoom } from "../../contexts/RoomProvider";
import ScoreDisplay from "./status/ScoreDisplay";

export default function Results() {
    const { roomState, roomProperties, endGame } = useRoom()

    function getScoreDescription(): string {
        let adjustedScore = (roomState.score / roomProperties.numberOfWords) * 13

        if (adjustedScore >= 13) {
            return "Perfect score! Can you do it again?"
        } else if (adjustedScore >= 12) {
            return "Incredible! Your friends must be impressed!"
        } else if (adjustedScore >= 11) {
            return "Awesome! That's a score worth celebrating!"
        } else if (adjustedScore >= 9) {
            return "Wow, not bad at all!"
        } else if (adjustedScore >= 7) {
            return "That's a good start. Try again!"
        } else if (adjustedScore >= 4) {
            return "That's a good start. Try again!"
         } else {
            return "Try again, and again, and again."
         }
    }

    return (
        <div style={{display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
            <Typography variant="h4" style={{ height: "48px", lineHeight: "48px" }}>
                {getScoreDescription()}
            </Typography>
            <ScoreDisplay state={roomState} properties={roomProperties} isGameFinished={true} />
            <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "64px", width: "150px" }}
                onClick={endGame}>
                End Game
            </Button>
        </div>
    )
}