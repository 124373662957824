import { IconButton, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import './HintCard.css';
import { useTheme } from "@mui/material/styles"
import Tooltip from '@mui/material/Tooltip';
import { Player, ReviewHint } from '../../../models/ServerModels';
import { useRoom } from '../../../contexts/RoomProvider';
import { colorStringFromColor } from '../../../util/ColorUtil';

interface IHintCardProps {
    player: Player,
    invertFlip: boolean,
    showApproval: boolean,
    approved: boolean,
    canModerate: boolean,
}

export default function HintCard({ player, invertFlip, showApproval, canModerate }: IHintCardProps) {

    const unselectedCssClass = (!player.isReady ? " card-dimmed" : "")
    const theme = useTheme()
    const { reviewHint } = useRoom()

    function getCardValueFontSize(value: string): number {
        let maxFontSize = 32
        let decreasePerCharacter = 0.55
        return Math.max(12, maxFontSize - decreasePerCharacter * value.length)
    }

    return (
        <div className={"card-container"}>
            <div className={"hint-card hint-card-animate"}
                style={{ marginBottom: "8px", alignContent: "center", justifyContent: "center", zIndex: 10, WebkitTransform: invertFlip ? 'rotateY(180deg)' : '' }}>
                <div className={"card-front" + unselectedCssClass} style={{
                    backgroundColor: colorStringFromColor(player.color)
                }} />
                <div className={"card-back" + unselectedCssClass}
                    style={{
                        transform: "rotateY(180deg)",
                        fontSize: getCardValueFontSize(player.hint.value ?? ""),
                        color: !showApproval ? (player.hint.approved ? theme.palette.info.dark : theme.palette.error.dark) : (player.hint.approved ? theme.palette.success.dark : theme.palette.error.dark),
                        whiteSpace: "initial"
                    }}>
                    <div style={{
                        backfaceVisibility: "hidden",
                        wordWrap: "break-word",
                        width: "90%"
                    }}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            {player.hint.value}
                            {showApproval || !player.hint.approved ?
                                <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                    {player.hint.approved ? <CheckIcon color="success" /> : <ClearIcon color="error" />}
                                </div>
                                : <></>
                            }
                        </div>

                    </div>
                </div>
            </div>
            <div style={{ display: "flex", transform: "translateY(15px)", alignItems: "center" }}>
                {<Typography align="center" style={{ fontSize: 18, overflowWrap: "break-word", width: "120px" }}> {player.name} </Typography>}
                {
                    showApproval && canModerate ?
                        <>
                            <Tooltip title="Approve">
                                <IconButton
                                    color="success"
                                    aria-label="approve"
                                    component="label"
                                    onClick={() => reviewHint(new ReviewHint(player.id, true))} >
                                    <CheckIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Deny">
                                <IconButton
                                    color="error"
                                    aria-label="deny"
                                    component="label"
                                    onClick={() => reviewHint(new ReviewHint(player.id, false))} >
                                    <ClearIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                        : <></>
                }
            </div>

        </div>
    )
}