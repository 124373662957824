/*
    Contains all of the models shared between the server and client.

    Ideally this file would be shared, for now just copy it to both the client and server projects.
*/

export class Player {
    constructor(
        public id: string,
        public name: string,
        public color: Color,
        public isOnline: boolean,
        public isReady: boolean,
        public hint: Hint) { }
}

export enum Color {
    Red = "Red",
    Blue = "Blue",
    Green = "Green",
    Yellow = "Yellow",
    Orange = "Orange",
    Purple = "Purple",
    Pink = "Pink",
    Cyan = "Cyan"
}

export class Room {
    constructor(public clientData: RoomClientData) { }
}

export class RoomClientData {
    constructor(
        public properties: RoomProperties,
        public state: RoomState) { }
}

export class RoomProperties {
    constructor(
        public name: string,
        public numberOfWords: number,
        public customWords: string[]) { }
}

export const defaultNumberOfWords = 13

export class RoomState {
    constructor(
        public players: Player[],
        public status: RoomStatus,
        public currentPlayerIndex: number,
        public moderatorPlayerIndex: number,
        public currentWord: string,
        public guess: string,
        public guessApproved: boolean,
        public remainingWords: string[],
        public score: number,
        public historyLog: string[]) { }
}

export enum RoomStatus {
    WaitingToStart = "WaitingToStart",
    GivingClues = "GivingClues",
    ReviewClues = "ReviewClues",
    Guessing = "Guessing",
    ReviewGuess = "ReviewGuess",
    GuessResult = "GuessResult",
    Results = "Results"
}

export class Hint {
    constructor(
        public value: string,
        public approved: boolean) { }
}

export enum StatusMessage {
    Success = "success",
    Failure = "failure"
}

export class JoinRoomRequest {
    constructor(
        public name: string,
        public color: Color) { }
}

export class JoinRoomResponse {
    constructor(
        public room: RoomClientData,
        public playerId: string
    ) { }
}

export class JoinFailedResponse {
    constructor(public reason: FailureReason) { }
}

export class FailureReason {
    constructor(
        public key: ErrorMessage,
        public description: string) { }
}

export enum ErrorMessage {
    InvalidId = "InvalidId",
    NotFound = "NotFound",
    Error = "Error"
}

export class ReviewHint {
    constructor(public playerId: string, public approved: boolean) { }
}

export enum SocketMessage {
    Connect = "connect",
    ConnectFailed = "connect-failed",
    RoomJoined = "room-joined",
    StateUpdated = "state-updated",
    JoinRoom = "join-room",
    Disconnect = "disconnect", // Must be "disconnect" for socket.io
    JoinFailed = "join-failed",
    StartGame = "start-game",
    GiveHint = "give-hint",
    ReviewHint = "review-hint",
    ReviewGuess = "review-guess",
    ModeratorApprove = "moderator-approve",
    GiveGuess = "give-guess",
    EndGame = "end-game"
}