import { Typography } from "@mui/material";
import { useEffect } from "react";
import { appName } from "../util/util";

export default function NotFound() {
    useEffect(() => {
        document.title = `Page not found - ${appName}`
    }, [])

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <div style={{
                textAlign: 'center'
            }}>

                <Typography variant="h2"> 404 </Typography>
                <Typography variant="h5"> Nothing to see here. </Typography>

            </div>

        </div>
    )
}
