import React, { useContext, useEffect, useState } from 'react'
import io, { Socket } from 'socket.io-client'

const SocketContext = React.createContext({} as ISocketContext)

export function useSocket() {
    return useContext(SocketContext)
}

interface ISocketProviderProps {
    roomId: string,
    playerId: string,
    children: JSX.Element | JSX.Element[]
}

interface ISocketContext {
    socket: Socket | undefined
    setSocket: (socket: Socket) => void
    playerId: string
}

export default function SocketProvider({ roomId, playerId, children }: ISocketProviderProps) {
    const [socket, setSocket] = useState<Socket>()
    /*
        Use this for testing a local server:

        let newSocket = io(`http://localhost:${8081}`, // Remove this first parameter for production
            { query: { roomId, playerId } }
        )
    */

    useEffect(() => {
        // Pass in localhost url for testing, remove it for production
        console.log(`trying to conect with ${roomId} + ${playerId}`)
        let newSocket = io(//`http://localhost:${8081}`,
            { query: { roomId, playerId }, withCredentials: true, transports: ['websocket'] }
        )
        setSocket(newSocket)
        return () => {
            newSocket.close()
        }
    }, [roomId, playerId])

    const value: ISocketContext = {
        socket: socket,
        setSocket: setSocket,
        playerId: playerId
    }

    return (
        <SocketContext.Provider value={value}>
            {children}
        </SocketContext.Provider>
    )
}
