import React, { useCallback, useEffect } from "react"
import { useContext, useState } from "react"
import { Socket } from "socket.io-client"
import { RoomState, RoomProperties, Player, JoinRoomRequest, RoomStatus, SocketMessage, RoomClientData, Room, JoinRoomResponse, ReviewHint } from "../models/ServerModels"
import { usePrevious } from "../util/util"
import { useSocket } from "./SocketProvider"

interface IRoomContext {
    roomState: RoomState
    setRoomState: (state: RoomState) => void
    previousRoomState: RoomState | undefined
    roomProperties: RoomProperties
    setRoomProperties: (properties: RoomProperties) => void
    previousRoomProperties: RoomProperties | undefined
    hints: string[],
    setHints: (hints: string[]) => void
    player: Player | undefined
    isCurrentPlayer: boolean,
    isModerator: boolean,
    connectionState: ConnectionState
    setConnectionState: (state: ConnectionState) => void
    joinRoom: (request: JoinRoomRequest) => void
    startGame: () => void
    giveHint: (hint: string) => void,
    moderatorApprove: () => void,
    reviewHint: (review: ReviewHint) => void,
    giveGuess: (guess: string) => void,
    reviewGuess: (approved: boolean) => void,
    endGame: () => void
}

export enum ConnectionState {
    Disconnected = "Disconnected",
    WaitingToJoin = "WaitingToJoin",
    Connected = "Connected",
    InvalidRoom = "InvalidRoom",
    ConnectFailed = "ConnectFailed"
}

interface IRoomProviderProps {
    children: JSX.Element | JSX.Element[]
}

const RoomContext = React.createContext({} as IRoomContext)

export function useRoom() {
    return useContext(RoomContext)
}

export default function RoomProvider({ children }: IRoomProviderProps) {
    const [roomState, setRoomState] = useState<RoomState>(new RoomState([], RoomStatus.WaitingToStart, 0, 0, "", "", true, [], 0, []))
    const previousRoomState = usePrevious<RoomState | undefined>(roomState)
    const [roomProperties, setRoomProperties] = useState<RoomProperties>(new RoomProperties("", 0, []))
    const previousRoomProperties = usePrevious<RoomProperties | undefined>(roomProperties)
    const [hints, setHints] = useState<string[]>([])
    const [connectionState, setConnectionState] = useState<ConnectionState>(ConnectionState.WaitingToJoin)
    const [player, setPlayer] = useState<Player>()
    const [isCurrentPlayer, setIsCurrentPlayer] = useState(false)
    const [isModerator, setIsModerator] = useState(false)
    const socket = useSocket()
    const [playerId, setPlayerId] = useState(socket.playerId)

    const value: IRoomContext = {
        roomState: roomState,
        setRoomState: setRoomState,
        previousRoomState: previousRoomState,
        roomProperties: roomProperties,
        setRoomProperties: setRoomProperties,
        previousRoomProperties: previousRoomProperties,
        hints: hints,
        setHints: setHints,
        player: player,
        isCurrentPlayer: isCurrentPlayer,
        isModerator: isModerator,
        connectionState: connectionState,
        setConnectionState: setConnectionState,
        joinRoom: joinRoom,
        startGame: startGame,
        giveHint: giveHint,
        moderatorApprove: moderatorApprove,
        reviewHint: reviewHint,
        giveGuess: giveGuess,
        reviewGuess: reviewGuess,
        endGame: endGame
    }

    function joinRoom(request: JoinRoomRequest) {
        socket.socket?.emit(SocketMessage.JoinRoom, request)
    }

    function startGame() {
        socket.socket?.emit(SocketMessage.StartGame)
    }

    function giveHint(hint: string) {
        socket.socket?.emit(SocketMessage.GiveHint, hint)
    }

    function reviewHint(review: ReviewHint) {
        socket.socket?.emit(SocketMessage.ReviewHint, review)
    }

    function moderatorApprove() {
        socket.socket?.emit(SocketMessage.ModeratorApprove)
    }

    function giveGuess(guess: string) {
        socket.socket?.emit(SocketMessage.GiveGuess, guess)
    }

    function reviewGuess(approve: boolean) {
        socket.socket?.emit(SocketMessage.ReviewGuess, approve)
    }

    function endGame() {
        socket.socket?.emit(SocketMessage.EndGame)
    }

    const handleConnect = useCallback(() => {
        console.log("connected!")
        setConnectionState(ConnectionState.WaitingToJoin)
    }, [])

    const handleRoomJoined = useCallback(({ room, playerId }: JoinRoomResponse) => {
        setPlayerId(playerId) // Player id may have updated if we joined to replace an existing player
        setRoomState(room.state)
        setRoomProperties(room.properties)
        setConnectionState(ConnectionState.Connected)
    }, [])

    const handleJoinFailed = useCallback(() => {
        setConnectionState(ConnectionState.InvalidRoom)
    }, [])

    const handleConnectFailed = useCallback(() => {
        setConnectionState(ConnectionState.ConnectFailed)
    }, [])

    const handleStateUpdated = useCallback(({ state }: RoomClientData) => {
        setRoomState(state)
    }, [])

    class ServerResponse {
        constructor(public message: SocketMessage, public action: any) { }
    }

    const serverResponses = {
        connect: new ServerResponse(SocketMessage.Connect, handleConnect),
        connectFailed: new ServerResponse(SocketMessage.ConnectFailed, handleConnectFailed),
        disconnect: new ServerResponse(SocketMessage.Disconnect, handleConnectFailed),
        roomJoined: new ServerResponse(SocketMessage.RoomJoined, handleRoomJoined),
        joinFailed: new ServerResponse(SocketMessage.JoinFailed, handleJoinFailed),
        roomStateUpdated: new ServerResponse(SocketMessage.StateUpdated, handleStateUpdated)
    }

    function handleResponse(response: ServerResponse): (() => Socket<any, any>) | null {
        if (socket.socket == null) return null
        socket.socket!.on(response.message, response.action)
        return () => socket.socket!.off(response.message)
    }

    useEffect(() => {
        handleResponse(serverResponses.connect)
    }, [socket, serverResponses.connect])
    useEffect(() => {
        handleResponse(serverResponses.connectFailed)
    }, [socket, serverResponses.connectFailed])
    useEffect(() => {
        handleResponse(serverResponses.disconnect)
    }, [socket, serverResponses.disconnect])
    useEffect(() => {
        handleResponse(serverResponses.roomJoined)
    }, [socket, serverResponses.roomJoined])
    useEffect(() => {
        handleResponse(serverResponses.joinFailed)
    }, [socket, serverResponses.joinFailed])
    useEffect(() => {
        handleResponse(serverResponses.roomStateUpdated)
    }, [socket, serverResponses.roomStateUpdated])

    useEffect(() => {
        updateCurrentPlayers()
    }, [roomState])

    function updateCurrentPlayers() {
        let findPlayer = roomState.players.find(p => p.id === playerId)
        console.log("updateCurrentPlayers")
        console.log(roomState.players.map(p => p.id))
        console.log("socket.playreId = " + playerId)

        if (findPlayer) {
            setPlayer(findPlayer)
            let playerIndex = roomState.players.findIndex(p => p.id === findPlayer!.id)
            setIsCurrentPlayer(playerIndex === roomState.currentPlayerIndex)
            setIsModerator(playerIndex === roomState.moderatorPlayerIndex)
            console.log("isCurrentPlayer: " + isCurrentPlayer)
        }
    }

    return (
        <RoomContext.Provider value={value}>
            {children}
        </RoomContext.Provider>
    )
}