import { Button, Typography } from "@mui/material";
import { useRoom } from "../../contexts/RoomProvider";
import { RoomStatus } from "../../models/ServerModels";
import { colorStringFromColor } from "../../util/ColorUtil";

export default function WaitingToStart() {
    const { roomState, startGame } = useRoom()

    return (
        <>
            <Typography variant="h4" style={{ height: "48px", lineHeight: "48px" }}>
                Waiting to Start
            </Typography>
            <Typography variant="h6">
                Players:
                <div>
                    {
                        roomState.players.map(p =>
                            <div key={`player-${p.id}`} style={{ color: colorStringFromColor(p.color) }} >
                                {p.name}
                            </div>)
                    }

                </div>
            </Typography>
            <Button
                variant="contained"
                color="primary"
                style={{ margin: "8px", width: "150px" }}
                onClick={startGame}
                disabled={roomState.players.length < 2}>
                Start
            </Button>
        </>
    )
}