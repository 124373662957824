import { Container, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ConnectionState, useRoom } from "../../contexts/RoomProvider"
import { Color, JoinRoomRequest } from "../../models/ServerModels"
import EnterName from "./EnterName"
import RoomPlaying from "./RoomPlaying"

export default function RoomBase() {
    const { connectionState, joinRoom, roomProperties, roomState } = useRoom()
    const [hasEnteredName] = useState(false)
    const navigate = useNavigate();

    function onFinishEnteringName(name: string, color: Color) {
        joinRoom(new JoinRoomRequest(name, color))
    }

    useEffect(() => {
        if (connectionState === ConnectionState.Connected) {
            //setPreviousRoomId(id)
            document.title = roomProperties.name
        } else if (connectionState === ConnectionState.InvalidRoom) {
            navigate("/404")
        }
    }, [connectionState])

    function renderState() {
        switch (connectionState) {
            case ConnectionState.Disconnected:
                return <></>
            case ConnectionState.WaitingToJoin:
                    return !hasEnteredName ? <EnterName onFinishEnteringName={onFinishEnteringName} /> : <div> Joining... </div>
            case ConnectionState.Connected:
                return <RoomPlaying />
            case ConnectionState.ConnectFailed:
                return <Container style={{ height: "80vh" }}>
                    <Typography variant="h5">Disconnected from room</Typography>
                </Container>
            case ConnectionState.InvalidRoom:
                return <Container style={{ height: "80vh" }}>
                    <Typography variant="h5">Invalid room Id</Typography>
                </Container>
            default:
                return <div> Invalid state! </div>
        }
    }

    return (
        <>
            {renderState()}
        </>
    )
}
