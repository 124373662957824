
import { AppBar, Toolbar, IconButton, Dialog, useTheme, Grow, Typography, styled } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import anime from "animejs";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import './Header.css';
import Settings from "../settings/Settings";

export default function Header() {
    const theme = useTheme()
    const [settingsOpen, setSettingsOpen] = React.useState(false);

    function handleSettingsClicked() {
      setSettingsOpen(true)
    }
  
    function handleCloseSettings() {
      setSettingsOpen(false)
    }
  
    function animateCardHover() {
        
      let timeline = anime.timeline({
        targets: ".animCardLogoFront",
        easing: 'easeOutElastic'
      })
      timeline.add({
        targets: ".animHeaderLogoCircle",
        duration: 800,
        scale: 1.25,
      })
      timeline.add({
        targets: ".animHeaderLogoNumber",
        delay: 100,
        duration: 800,
        scale: 1.25,
      }, "-=800")
      timeline.add({
        targets: ".animHeaderLogoCircle",
        duration: 800,
        scale: 1,
      })
      timeline.add({
        targets: ".animHeaderLogoNumber",
        delay: 100,
        duration: 800,
        scale: 1,
      }, "-=800")
    }
  
    return (
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Link to={"/"} onMouseEnter={animateCardHover}>
            <div style={{ position: "relative", width: "48px", height: "48px", margin: "16px", display: "flex", alignItems: "center", justifyContent: "center"}}>
              <Typography className="animHeaderLogoNumber" variant="h4" fontWeight="bold" color={theme.palette.background.default} style={{ position: "absolute", zIndex: 2 }}>1</Typography>
              <div className="animHeaderLogoCircle logoCardMini" style={{ backgroundColor: theme.palette.primary.main, zIndex: 1}} />
            </div>
          </Link>
  
          <div style={{ color: theme.palette.text.primary, fontSize: "14px", fontWeight: "bold", fontFamily: "Helvetica" }}>
            ONLY ONE
          </div>
  
          <div style={{ flex: 1 }} />
          <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleSettingsClicked} style={{ margin: "16px" }}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
  
        <Dialog 
          fullScreen
           open={settingsOpen} 
           onClose={handleCloseSettings}>
          <Settings handleCloseSettings={handleCloseSettings}/>
        </Dialog>
      </AppBar>
    )
  }
  