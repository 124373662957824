import Home from './home/Home';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SettingsProvider from '../contexts/SettingsProvider';
import Room from './room/Room';
import NotFound from './NotFound';
import { CssBaseline } from '@mui/material';
import Header from './header/Header';

function App() {
  return (
    <SettingsProvider>
      <CssBaseline>
      <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/room/:id"  element={<Room />} />
        <Route path="/404"  element={<NotFound />} />
        <Route path="/*" element={<Navigate replace to="/404" />} />
      </Routes>
      </BrowserRouter>
      </CssBaseline>
    </SettingsProvider>
  );
}

export default App;
