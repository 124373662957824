import { useParams } from 'react-router-dom';
import {v4 as uuid} from "uuid"; 
import RoomProvider from "../../contexts/RoomProvider";
import SocketProvider from "../../contexts/SocketProvider";
import RoomBase from './RoomBase';

export default function Room() {
    let { id } = useParams()

    return (
        <SocketProvider roomId={id ?? ""} playerId={uuid()}>
            <RoomProvider>
                <RoomBase />
            </RoomProvider>
        </SocketProvider>
    )
}