import CheckIcon from "@mui/icons-material/Check"
import ClearIcon from '@mui/icons-material/Clear'

import { Container, TextField, Button, IconButton, Box } from "@mui/material"
import { RoomStatus } from "../../../models/ServerModels"

interface IHintTextInputProps {
    text: string
    setText: (text: string) => void
    submittedText: string,
    roomStatus: RoomStatus,
    handleReadyClicked: () => void
}

export default function HintTextInput({ text, setText, submittedText, roomStatus, handleReadyClicked}: IHintTextInputProps) {
    return (
        <div style={{ display:"flex", justifyContent: "center" }}>
            <TextField
                id="hint-text-field"
                label={roomStatus === RoomStatus.GivingClues ? "Hint" : "Guess"}
                key={`hint-textfield`}
                autoComplete="off"
                disabled={submittedText.length > 0}
                inputProps={{ maxLength: 30 }}
                variant="outlined"
                fullWidth
                style={{ margin: "8px", maxWidth: "300px" }}
                value={text}
                onChange={(e) => {
                    setText(e.target.value)
                }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleReadyClicked()
                    }
                  }} />

            <Button
                id="hint-done-button"
                className="actionButton"
                variant="contained"
                color={submittedText.length > 0 ? "error" : "primary"}
                disabled={text.length <= 0}
                endIcon={submittedText.length === 0 ? <CheckIcon /> : <ClearIcon />}
                onClick={handleReadyClicked}
                style={{ margin: "12px" }}>
                {submittedText.length === 0 ? "Done" : "Cancel"}
            </Button>
        </div>
    )
}