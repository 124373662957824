import { Stack, Typography, useTheme } from "@mui/material"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Color, Player } from "../../../models/ServerModels"
import { colorStringFromColor } from "../../../util/ColorUtil";

interface IPlayerListProps {
    players: Player[],
    currentPlayerIndex: number
}

export default function PlayerList({ players, currentPlayerIndex }: IPlayerListProps) {
    const theme = useTheme()

    return <Stack style={{ width: "250px" }}>
        {
            players.map((p, index) =>
                <div style={{ display: "flex", alignItems: "center", marginLeft: currentPlayerIndex === index ? "16px" : "0" }} key={`player-list-${p.id}`}>
                    <ArrowForwardIcon
                        style={{
                            color: colorStringFromColor(p.color),
                            opacity: currentPlayerIndex === index ? 1 : 0
                        }}
                    />
                    <Typography 
                        variant="h6"
                        color={colorStringFromColor(p.color)}
                        fontWeight={ currentPlayerIndex === index ? "bold" : "medium"} 
                        noWrap
                        style={{
                            opacity: p.isOnline ? 1 : 0.5
                        }}>
                            {p.name + (!p.isOnline ? " (Offline)" : "")}
                    </Typography>
                </div>
            )
        }
    </Stack>
}