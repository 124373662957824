import { Box, Typography, FormControl, TextField, Button, Radio, radioClasses, RadioGroup, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Done from '@mui/icons-material/Done';
import { useSettings } from '../../contexts/SettingsProvider';
import { isBlank } from '../../util/util'
import anime from 'animejs/lib/anime.es.js';
import { Color } from '../../models/ServerModels';
import { colorStringFromColor } from '../../util/ColorUtil';

interface IEnterNameProps {
    onFinishEnteringName: (name: string, color: Color) => void
}

export default function EnterName({ onFinishEnteringName }: IEnterNameProps) {

    const [nameText, setNameText] = useState("")
    const { name, setName, color, setColor } = useSettings()
    const [selectedColor, setSelectedColor] = useState(color)
    const theme = useTheme()

    function onEnterSelected() {
        if (nameText.length === 0) {
            return
        }
        onFinishEnteringName(nameText, selectedColor)
        setName(nameText)
        setColor(selectedColor)
    }

    function handleNameChanged(e: React.ChangeEvent<HTMLInputElement>) {
        setNameText(e.target.value)
    }

    useEffect(() => {
        anime({
            targets: ".animFadeIn",
            scale: [0.55, 1],
            opacity: [0, 1],
            easing: 'easeInOutSine',
            duration: 200
        })
        setNameText(name)
    }, [])

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            top: '25%',
            bottom: '50%',
            left: '64px',
            right: '64px',
            justifyContent: 'center',
        }}>

            <Box className="animFadeIn" maxWidth="sm" style={{ textAlign: "center" }}>
                <Box m={4}>
                    <Typography variant="h4">
                        Enter your info
                    </Typography>
                </Box>
                <FormControl style={{ width: "400px" }}>
                    <TextField
                        id="name-field"
                        label="Name"
                        variant="outlined"
                        autoComplete="off"
                        inputProps={{ maxLength: 30 }}
                        onChange={handleNameChanged}
                        defaultValue={name}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onEnterSelected()
                            }
                          }} 
                        style={{ marginBottom: "16px" }} />
                </FormControl>
                <Box m={4}>
                    <RadioGroup
                        aria-labelledby="product-color-attribute"
                        defaultValue={color}
                        onChange={v => setSelectedColor(v.target.value as Color)}
                        sx={{ gap: 2, flexWrap: 'wrap', flexDirection: 'row' }}
                    >
                        {Object.keys(Color).map(c => c as Color).map(
                            (color) => (
                                <Box
                                    key={color}
                                    sx={{
                                        position: 'relative',
                                        width: 40,
                                        height: 40,
                                        flexShrink: 0,
                                        bgcolor: `${colorStringFromColor(color)}`,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    <Radio
                                    style={{color: theme.palette.background.default}}
                                        checkedIcon={<Done fontSize="large" />}
                                        value={color}
                                    />
                                </Box>
                            ),
                        )}
                    </RadioGroup>
                </Box>
                <Box m={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: "8px", width: "150px" }}
                        onClick={onEnterSelected}
                        disabled={isBlank(nameText)}>
                        Join
                    </Button>
                </Box>
            </Box>

        </div>
    )
}
