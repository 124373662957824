import { AppBar, Toolbar, IconButton, Typography, Container, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { themeMap } from '../../themes/theme'
import ThemeItem from "../header/ThemeItem";
import { useSettings } from "../../contexts/SettingsProvider";

interface ISettingsProps {
    handleCloseSettings: () => void
}

export default function Settings({ handleCloseSettings }: ISettingsProps) {
    const { theme, setTheme } = useSettings()

    function renderThemesList() {
        return Array.from(themeMap.entries()).map(list =>
            <ThemeItem key={`theme-item-${list[0]}`} themeKey={list[0]} theme={list[1]} isSelected={theme == list[0]} onSelected={setTheme} />
        )
    }

    return (
        <div>
            <AppBar>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleCloseSettings} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6">
                        Settings
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container style={{ marginTop: "80px" }}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">

                        <Typography variant="h6">Theme</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Container>
                            <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '16px' }}>
                                {renderThemesList()}
                            </div>
                        </Container>
                    </AccordionDetails>
                </Accordion>
            </Container>
        </div>
    )
}