import { Button, Dialog, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { RoomProperties } from "../../models/ServerModels";
import CreateDialogContent from "./join/CreateDialogContent";
import JoinDialogContent from "./join/JoinDialogContent";
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import anime from "animejs";

export default function Home() {

    const [showCreate, setShowCreate] = useState(false)
    const [showJoin, setShowJoin] = useState(false)
    const navigate = useNavigate();
    const theme = useTheme()

    //axios.defaults.baseURL = "http://127.0.0.1:8081"

    useEffect(() => {
        let timelineCircle = anime.timeline({
            targets: ".animLogoCircle",
            easing: 'easeOutElastic'
        })
        timelineCircle.add({
            duration: 500,
            delay: 100,
            scale: [0, 1],
        })
        timelineCircle.add({
            targets: ".animAppTitle, .animAppSubtitle, .animButtons",
            delay: anime.stagger(50),
            translateX: [-50, 0],
            duration: 1200,
            opacity: 1,
        }, "-=650")
        let timelineNumber = anime.timeline({
            targets: ".animLogoNumber",
            easing: 'easeOutElastic'
        })
        timelineNumber.add({
            duration: 500,
            delay: 150,
            scale: [0, 1],
        })
    }, [])

    function handleCreateSubmit(properties: RoomProperties) {
        axios.get('/api/create', { params: properties })
        .then((res) => {
            if (res.data.status === "success" && res.data.id != null) {
                navigate(`/room/${res.data.id}`)
            } else {
                console.error("Create failed")
            }
        }).catch((error) => {
            console.error(error)
        });
    }

    function handleCreateCancelled() {
        setShowCreate(false)
    }

    function handleJoinSubmit(id: string) {
        setShowJoin(false)
        navigate(`/room/${id}`)
    }

    function handleJoinCancelled() {
        setShowJoin(false)
    }

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            top: '25%',
            bottom: '50%',
            left: '64px',
            right: '64px',
            justifyContent: 'center',
        }}>

            <div style={{ position: "relative", width: "120px", height: "120px" }}>
                <div style={{ position: "relative", margin: "16px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Typography className="animLogoNumber" variant="h3" fontWeight="bold" color={theme.palette.background.default} style={{ position: "absolute", zIndex: 2 }}>1</Typography>
                <div className="animLogoCircle" style={{ width: "80px", height: "80px", borderRadius: "50%", backgroundColor: theme.palette.primary.main, zIndex: 1}} />
                </div>
            </div>

            <div>
                <Typography className="animAppTitle" variant="h3" style={{opacity: "0"}}>
                    Only One
                </Typography>
                <Typography className="animAppSubtitle" variant="h5" style={{opacity: "0"}}>
                    One game to rule them all
                </Typography>
                <div className="animButtons" style={{ height: "24px", marginTop: "16px", opacity: "0"}}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: '24px' }}
                        onClick={() => setShowCreate(true)}>
                        Create
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowJoin(true)}>
                        Join
                    </Button>
                </div>
            </div>

            <Dialog fullWidth={true} open={showCreate} onClose={handleCreateCancelled} aria-labelledby="form-dialog-title">
                <CreateDialogContent onCancel={handleCreateCancelled} onCreate={handleCreateSubmit} existingProperties={null} />
            </Dialog>
            <Dialog fullWidth={true} open={showJoin} onClose={handleJoinCancelled} aria-labelledby="form-dialog-title">
                <JoinDialogContent onCancel={handleJoinCancelled} onJoin={handleJoinSubmit} />
            </Dialog>

        </div>
    )
}