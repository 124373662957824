import { Color } from "../models/ServerModels";

export function colorStringFromColor(color: Color) {
    switch (color) {
        case Color.Red: return "#F44336"
        case Color.Blue: return "#03A9F4"
        case Color.Green: return "green"
        case Color.Yellow: return "#ffcc33"
        case Color.Orange: return "#FF9422"
        case Color.Purple: return "#673AB7"
        case Color.Pink: return "#E91E85"
        case Color.Cyan: return "#00BCD4"
    }
}